<template>
    <Dashboard />

    <!--
    <div class="col-12">
        <Dashboard />
    </div>
-->
</template>

<script>
// @ is an alias to /src
import Dashboard from '@/components/bootstrapDashboard/Dashboard.vue'

export default {
    name: 'DashboardView',
    components: {
        Dashboard
    }
}
</script>
