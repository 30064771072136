<template>
    <div class="starter-template">
        <h1>Dashboard</h1>
        <p class="lead">This is an dashboard view.</p>
    </div>
</template>

<style>

.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
  width: 100%;
}
</style>
